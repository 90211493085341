import { _ as __decorate } from './_tslib-b3b41ee7.js';
import { LitElement, css, html } from 'lit';
import { property, customElement } from 'lit/decorators.js';
import './formfield-c86449dc.js';

let id = 0;
let NxtInput = class NxtInput extends LitElement {
    constructor() {
        super();
        this._focused = false;
        this.type = 'text';
        this.value = '';
        this.outline = false;
        this.label = '';
        this.arialabel = '';
        this.placeholder = '';
        this.disabled = false;
        this.invalid = false;
        this._inputId = `nxt-input-${id++}`;
    }
    static get styles() {
        return css `
      :host {
        display: block;
      }
    `;
    }
    _onInput(event) {
        this.value = event.target.value;
    }
    _onBlur() {
        this._focused = false;
    }
    _onFocus() {
        this._focused = true;
    }
    render() {
        if (!this.arialabel)
            this.arialabel = this.label;
        return html `
      <nxt-formfield
        ?hasPlaceholder=${!!this.placeholder}
        labelFor=${this._inputId}
        label=${this.label}
        ?outline=${this.outline}
        ?disabled=${this.disabled}
        ?hasValue=${!!this.value}
        ?focused=${this._focused}
        ?invalid=${this.invalid}
      >
        <slot slot="prefix" name="prefix"></slot>
        <slot slot="suffix" name="suffix"></slot>
        <slot slot="hint" name="hint"></slot>
        <slot slot="appendix" name="appendix"></slot>

        <input
          placeholder=${this.placeholder}
          id=${this._inputId}
          type=${this.type}
          aria-label=${this.arialabel}
          .value=${this.value}
          @focus=${this._onFocus}
          @blur=${this._onBlur}
          @input="${this._onInput}"
          ?disabled="${this.disabled}"
        />
      </nxt-formfield>
    `;
    }
};
__decorate([
    property({ type: Boolean })
], NxtInput.prototype, "_focused", void 0);
__decorate([
    property()
], NxtInput.prototype, "type", void 0);
__decorate([
    property()
], NxtInput.prototype, "value", void 0);
__decorate([
    property({ type: Boolean })
], NxtInput.prototype, "outline", void 0);
__decorate([
    property()
], NxtInput.prototype, "label", void 0);
__decorate([
    property()
], NxtInput.prototype, "arialabel", void 0);
__decorate([
    property()
], NxtInput.prototype, "placeholder", void 0);
__decorate([
    property({ type: Boolean, reflect: true })
], NxtInput.prototype, "disabled", void 0);
__decorate([
    property({ type: Boolean })
], NxtInput.prototype, "invalid", void 0);
NxtInput = __decorate([
    customElement('nxt-input')
], NxtInput);

export { NxtInput as N };
